import React from "react";
import { Link } from "react-router-dom";
import './App.css';


const PageNot=()=>{
    return(
        <>
           <div className="container pt-5">
        <div className="row">
            <div className="col-md-12 text-center">
                <div className="error-template">
                    <h1>Oops!</h1>
                    <h2>The page you were trying to access cannot be found.</h2>
                    <div className="error-details mb-5">
                        You may have mistyped the address or the page may have moved
                    </div>
                    <div className="error-actions">
                        <Link to="/" className="btn btn-custom btn-lg"><span className="glyphicon glyphicon-home"></span>Take Me Home</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </>
    )
}
export default PageNot;
