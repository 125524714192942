import React from "react";
import { useState,useEffect,useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import '../styles/LoginCss.css'
import TruveyBlackLogo from '../Asset/Images/TruVey_logo-black.png';
import TruveyGreenLogo from '../Asset/Images/TruVey_logo-green.png';

const Header=()=>{
    const [isLoggedIn, setIsLoggedIn] = useState(false); 
    const UserName = sessionStorage.getItem("UserName");
    const ProfileImageUrl = sessionStorage.getItem("ProfileUrl");
    
    const navigate = useNavigate();
    useEffect(() => {
      const token = sessionStorage.getItem("JWTToken");
      if (token) {
        // User is authenticated if token exists
        setIsLoggedIn(true);
      } else {
        // Token is not present, user is not authenticated
        setIsLoggedIn(false);
      }
      
    }, []);
  
    // Handler to simulate logout
    const handleLogout = () => {
      setIsLoggedIn(false);
      sessionStorage.removeItem("JWTToken"); 
      sessionStorage.removeItem("UserName"); 
      sessionStorage.removeItem("ProfileUrl"); 
      sessionStorage.removeItem("RoleID"); 
      sessionStorage.removeItem("UserEmail"); 
      navigate("/")
    };
  
    return(
        <>
        <nav
      className="navbar navbar-light navbar-expand-lg fixed-top font-sanket shadow-sm"
      style={{ position: 'sticky', top: 0, backgroundColor: '#ffffff' }}>
      <div className="container p-md-0">
        {/* Logo */}
            {isLoggedIn ?
              (
                <>
                  <Link className="navbar-brand" to="/">
                    <img
                      src={TruveyGreenLogo}
                      alt="VRSamadhan"
                      width="110"
                      className="d-inline-block"
                    />
                  </Link>
                </>
              )
              : <>
                <Link className="navbar-brand" to="/">
                  <img
                    src={TruveyBlackLogo}
                    alt="VRSamadhan"
                    width="110"
                    className="d-inline-block"
                  />
                </Link>
              </>
            } 
        

        {/* Navbar toggler for smaller screens */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="mb-0 ps-1">
            {isLoggedIn ? (
              <>
               <Link className="nav-link" to="/SurveyList">
                    Survey List
                  </Link>
              </>
            ):
            <>
            </>
            }
          </ul>
          <ul className="navbar-nav ms-auto align-items-center">
            {/* Conditional Rendering Based on Login State */}
            {isLoggedIn ? (
              <>
                {/* If the user is logged in */}
                <li className="nav-item">
                  <span className="nav-link">Welcome, {UserName}</span>
                </li>
                <li className="nav-item">
                 
                    </li>
                    <div className="dropdown">
                      <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src={ProfileImageUrl} className="rounded-circle" alt="..." width="40px" height="40px"/>
                        {/* <asp:Image ID="UserImg" ImageUrl="../ProfilePhoto/UserProfile.png" runat="server" CssclassName="rounded-circle" Width="40" Height="40" /> */}
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                        <li>
                      <Link className="dropdown-item" to="/Profile">
                        Profile
                      </Link>
                        </li>
                        <li><a className="dropdown-item" href="#">Settings</a></li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li><a className="dropdown-item text-danger"  onClick={handleLogout}>Sign Out</a></li>
                      </ul>
                    </div>
                    <li className="nav-item">
                      
                    </li>
                  </>
                ) : (
                  <>
                    {/* If the user is not logged in */}
                    <li className="nav-item">
                      <Link className="nav-link active" to="/Login">
                        Login
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link active" to="/SignUp">
                        Sign Up
                      </Link>
                    </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
        </>
    )
}
export default Header;