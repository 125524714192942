import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import apiService from '../Services/apiService';
import '../styles/SurveyList.css';

const SurveyList = () => {
  const [UserDetails, setUserDetails] = useState(null); 
  const [surveyDetails, setSurveyDetails] = useState([]);
  const [activeTab, setActiveTab] = useState("active"); 
  const [loading, setLoading] = useState(true); 
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  // Fetch user details from API
  useEffect(() => {
    const token = sessionStorage.getItem("JWTToken");
    
    const fetchUserDetails = async () => { 
      if (token) {
        try {
          const response = await apiService.EncryptJWT(token);
          if (response) {
            setUserDetails(response); // Update state
          }
        } catch (error) {
          console.error("Error fetching user details:", error); 
        }
      } else {
        navigate("/Login");
      }
    };

    // Fetch survey details from API
    const fetchSurveyDetails = async () => {
      if (token) {
        try {
          const response = await apiService.GetSurveyDetails(token);
          if (response) {
            setSurveyDetails(response.SURVEY_DETAILS);
          }
        } catch (error) {
          console.error("Error fetching survey details:", error);
        }
      } else {
        navigate("/Login");
      }
    };

    fetchUserDetails();
    fetchSurveyDetails();
  }, [navigate]);

  // Update sessionStorage when UserDetails is fetched
  useEffect(() => {
    if (UserDetails) {
      const { Name, RoleID, ProfileImageUrl } = UserDetails?.RESPONSE || {};
      sessionStorage.setItem("UserName", Name);
      sessionStorage.setItem("ProfileUrl", ProfileImageUrl);
      sessionStorage.setItem("RoleID", RoleID);
      setLoading(false); // Set loading to false once data is fetched
    }
  }, [UserDetails]);

  const categorizedSurveys = {
    active: surveyDetails.filter(survey => survey.Status === "Active"),
    completed: surveyDetails.filter(survey => survey.Status === "Survey Ended"),
    draft: surveyDetails.filter(survey => survey.Status === "Incomplete" || survey.Status === "Inactive"),
  };
  // Filter surveys by search term for the active tab
  const filteredSurveys = categorizedSurveys[activeTab]?.filter((survey) =>
    survey.SurveyName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  // Tab switch handler
  const handleTabChange = (tab) => setActiveTab(tab);
  // Survey Card Component
  const SurveyCard = ({ survey }) => (
    <Link to={`/ManageSurvey/${survey.UniqueKey}`}>
      <div className="card Box m-2 p-3 shadow-sm" style={{ width: "10rem", height: "14rem" }}>
        <p className="card-text">Ending: {survey.SurveyEndDateTime || "N/A"}</p>
        <div className="card-body p-0 d-flex flex-column justify-content-between">
          <h6 className="card-title fw-bold">
            {survey.SurveyName.length > 40
              ? `${survey.SurveyName.slice(0, 40)}...`
              : survey.SurveyName}
          </h6>
          <p className="card-text">{survey.TotalQuestions} Responses</p>
        </div>
      </div>
    </Link>
  );

  return (
    <>
      <Header />
      <div className="container-fuild bg-Truvey py-5">
        <div className="container">
          <div className="row justify-content-evenly">
            <div className="Box p-4" style={{ minHeight: "200px", maxWidth: "350px" }}>
              <div>
                <h5 className="text-lg font-bold text-black">Profile</h5>
              </div>
              <div className="mt-3 d-flex justify-content-evenly">
                <div className="flex-wrap items-center justify-center Rounded-profile">
                  <p className="w-4/5 text-center text-xl font-semibold text-green-100">90%</p>
                  <p className="w-4/5 text-center text-xs text-black opacity-60">Completed</p>
                </div>
                <div className="flex w-50 p-3 align-items-center justify-center text-right">
                  <div className="inline-block text-center"> 
                    <p>+5% export the first report</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="Box p-4" style={{ minHeight: "200px", maxWidth: "350px" }}>
              <div className="text-end">
                <select name="" id="" className="DaysDropdown">
                  <option value="">3days</option>
                  <option value="">7days</option>
                  <option value="">15days</option>
                </select>
              </div>
              <div className="mt-3 d-flex justify-content-evenly">
                <div className="flex-wrap items-center justify-center">
                  <span>Survey Created</span>
                  <p><b>0</b></p>
                </div>
                <div className="flex w-50 align-items-center justify-center text-right">
                  <div className="inline-block text-center">
                    <span>Response rate</span>
                    <p><b>0%</b></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="Box p-4" style={{ minHeight: "200px", maxWidth: "350px" }}>
              <div>
                <i className="fa-solid fa-dollar-sign Gold"></i> Gold Plan
              </div>
              <div className="mt-3">
                <span>Due Amount</span>
                <p><b>INR 0</b></p>
              </div>
            </div>
          </div>
        </div>

        <div className="container py-4">
          {/* Tabs for switching between survey categories */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="nav nav-tabs">
              <button
                className={`nav-link ${activeTab === "active" ? "active" : ""}`}
                onClick={() => handleTabChange("active")}
              >
                Active Survey
              </button>
              <button
                className={`nav-link ${activeTab === "completed" ? "active" : ""}`}
                onClick={() => handleTabChange("completed")}
              >
                Completed
              </button>
              <button
                className={`nav-link ${activeTab === "draft" ? "active" : ""}`}
                onClick={() => handleTabChange("draft")}
              >
                Draft
              </button>
            </div>
            <input
              type="text"
              placeholder="Search..."
              className="form-control w-25"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Loading State */}
          {loading ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <div className="d-flex flex-wrap align-items-center">
              <Link to={"/Survey/CreateNew"}>
                <div className="card TruveyBG Box m-2 p-3 shadow-sm" style={{ width: "10rem", height: "14rem" }}>
                  <div className="card-body p-0 d-flex flex-column justify-content-center align-item-center text-center">
                    <h6 className="card-title fw-bold text-white">New Survey</h6>
                  </div>
                </div>
              </Link>
              {filteredSurveys?.length > 0 ? (
                filteredSurveys.map(survey => (
                  <SurveyCard key={survey.SurveyID} survey={survey} />
                ))
              ) : (
                <p className="text-muted">No surveys available in this category.</p>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SurveyList;
