import React from "react";
import Header from "./Header";
import { useState } from "react";
const CreateNew = () => {
    const [dobChecked, setDobChecked] = useState(false);
    const [mobNoChecked, setMobNoChecked] = useState(false);
    const [emailChecked, setEmailChecked] = useState(false);
    
    const [dobRequired, setDobRequired] = useState(false);
    const [mobRequired, setMobRequired] = useState(false);
    const [emailRequired, setEmailRequired] = useState(false);
    const [formData, setFormData] = useState({
        surveyName: '',
        surveyDesc: '',
        thumbnail: null,
        nameRequired: false,
        dobRequired: false,
        mobRequired: false,
        emailRequired: false,
        nameChecked: false,
        dobChecked: false,
        mobChecked: false,
        emailChecked: false,
    });

    const [errors, setErrors] = useState({
        surveyName: '',
        surveyDesc: '',
        thumbnail: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            thumbnail: file,
        });
    };

    const handleSwitchChange = (field) => {
        const requiredField = `${field}Required`;
        setFormData((prevState) => ({
            ...prevState,
            [`${field}Checked`]: !prevState[`${field}Checked`],
            [requiredField]: false,
        }));
    };

    const validateForm = () => {
        let formIsValid = true;
        let errors = {};

        if (!formData.surveyName) {
            formIsValid = false;
            errors.surveyName = "Survey Name is required.";
        }

        if (!formData.surveyDesc) {
            formIsValid = false;
            errors.surveyDesc = "Survey Description is required.";
        }

        if (formData.thumbnail && !['image/jpeg', 'image/png', 'image/gif', 'image/bmp'].includes(formData.thumbnail.type)) {
            formIsValid = false;
            errors.thumbnail = "Only image files (JPG, PNG, GIF, BMP) are allowed.";
        }

        setErrors(errors);
        return formIsValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

    };
    const handleDOBChange = () => {
        setDobChecked(!dobChecked);
        setDobRequired(!dobChecked);
      };
    
      const handleMobNoChange = () => {
        setMobNoChecked(!mobNoChecked);
        setMobRequired(!mobNoChecked);
      };
    
      const handleEmailChange = () => {
        setEmailChecked(!emailChecked);
        setEmailRequired(!emailChecked);
      };
    

    return (<>
        <Header />


        <div className="text-custom">
            <div className="container py-4">
                <div className="card p-3 col-7 m-auto p-2" style={{ fontSize: '1.0rem', borderRadius: '1rem' }}>
                    <div className="card-body">
                        <h2 className="text-center p-3">Create Survey</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="surveyName">Survey Name: </label>
                                <span style={{ color: 'red' }}>*</span>
                                <input
                                    type="text"
                                    id="surveyName"
                                    name="surveyName"
                                    className="form-control"
                                    value={formData.surveyName}
                                    onChange={handleChange}
                                />
                                <span className="text-danger">{errors.surveyName}</span>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="surveyDesc">Survey Description: </label>
                                <span style={{ color: 'red' }}>*</span>
                                <textarea
                                    id="surveyDesc"
                                    name="surveyDesc"
                                    rows="4"
                                    className="form-control"
                                    value={formData.surveyDesc}
                                    onChange={handleChange}
                                />
                                <span className="text-danger">{errors.surveyDesc}</span>
                            </div>

                            <div className="mb-4">
                                <label htmlFor="thumbnail">Thumbnail Image: </label>
                                <input
                                    type="file"
                                    id="thumbnail"
                                    name="thumbnail"
                                    className="form-control mb-3"
                                    accept=".jpg, .gif, .png, .bmp"
                                    onChange={handleFileChange}
                                />
                                <span className="text-danger">{errors.thumbnail}</span>
                            </div>

                            <hr />
                            <h5 className="text-center m-3">Pre-defined Questions</h5>
                            <div className="text-center">
                                <div className="row mb-2">
                                    <div className="col-md-6">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="nameSwitch"
                                                name="nameChecked"
                                                checked={formData.nameChecked}
                                                onChange={() => handleSwitchChange('name')}
                                            />
                                            <label className="form-check-label" htmlFor="nameSwitch">Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="nameRequiredSwitch"
                                                disabled
                                                checked={formData.nameRequired}
                                            />
                                            <label className="form-check-label" htmlFor="nameRequiredSwitch">Required</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-6">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="DOBSwitch"
                                                checked={dobChecked}
                                                onChange={handleDOBChange}
                                            />
                                            <label className="form-check-label" htmlFor="DOBSwitch">DOB</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="dobRequiredSwitch"
                                                checked={dobRequired}
                                                disabled
                                            />
                                            <label className="form-check-label" htmlFor="dobRequiredSwitch">Required</label>
                                        </div>
                                    </div>
                                </div>

                                {/* Mobile Number Section */}
                                <div className="row mb-2">
                                    <div className="col-md-6">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="MobilenoSwitch"
                                                checked={mobNoChecked}
                                                onChange={handleMobNoChange}
                                            />
                                            <label className="form-check-label" htmlFor="MobilenoSwitch">Mobile Number</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="mobRequiredSwitch"
                                                checked={mobRequired}
                                                disabled
                                            />
                                            <label className="form-check-label" htmlFor="mobRequiredSwitch">Required</label>
                                        </div>
                                    </div>
                                </div>

                                {/* Email Section */}
                                <div className="row mb-2">
                                    <div className="col-md-6">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="EmailSwitch"
                                                checked={emailChecked}
                                                onChange={handleEmailChange}
                                            />
                                            <label className="form-check-label" htmlFor="EmailSwitch">Email</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="emailRequiredSwitch"
                                                checked={emailRequired}
                                                disabled
                                            />
                                            <label className="form-check-label" htmlFor="emailRequiredSwitch">Required</label>
                                        </div>
                                    </div>
                                </div>

                                {/* Other fields (DOB, Mobile Number, Email) handled similarly */}
                                {/* Add other question checkboxes here */}

                                <button type="submit" className="btn btn-custom col-8 mt-5 mx-auto">
                                    Create Survey
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default CreateNew;