import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "./Header";
import apiService from "../Services/apiService";
import '../styles/ManageSurvey.css';

const ManageSurvey = () => {
    const [questionDetails, setQuestionDetails] = useState([]); // Corrected naming convention
    const [loading, setLoading] = useState(true);
    const { uniqueKey } = useParams();
    const token = sessionStorage.getItem("JWTToken");
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            const fetchQuestionData = async () => {
                try {
                    const response = await apiService.GetQuestionDetails(token, uniqueKey);
                    if (response && response.SURVEY_DETAILS) {
                        setQuestionDetails(response.SURVEY_DETAILS);
                        console.log(response.SURVEY_DETAILS);
                    } else {
                        console.error("No survey details found");
                    }
                } catch (error) {
                    console.error("Error fetching survey data", error);
                } finally {
                    setLoading(false);
                }
            };
            fetchQuestionData();
        } else {
            navigate("/Login");
        }
    }, [token, uniqueKey, navigate]);

    const handleOptionTypeChange = (questionId, newOptionType) => {
        setQuestionDetails((prevDetails) =>
            prevDetails.map((question) =>
                question.QuestionID === questionId
                    ? { ...question, OptionType: newOptionType }
                    : question
            )
        );
    };

    const renderQuestionInput = (question) => {
        if (question.OptionType) {
            return (
                <select
                    value={question.OptionType}
                    onChange={(e) => handleOptionTypeChange(question.QuestionID, e.target.value)}
                    className="Sanket-DropDownControl w-40 mb-3"
                >
                    <option value="Multiple Choice">Multiple Choice</option>
                    <option value="Check Boxes">Check Boxes</option>
                    <option value="Short Answer">Short Answer</option>
                    <option value="Paragraph">Paragraph</option>
                    <option value="Date">Date</option>
                    <option value="Answer in Number">Answer in Number</option>
                    <option value="Mobile Number">Mobile Number</option>
                    <option value="Email">Email</option>
                    <option value="Rating bar">Rating bar</option>
                </select>
            );
        }
    };

    const renderOption = (question) => {
        return (
            <div className="my-3">
                {question.Options && question.Options.length > 0 ? (
                    question.Options.map((option, index) => (
                        <div className="option-item ps-4 my-3" key={index}>
                            <input
                                type="text"
                                name={question.QuestionID}
                                id={`${question.QuestionID}-${index}`}
                                className="Sanket-Control w-40"
                                value={option.OptionName || ""}
                                onChange={(e) => {
                                    const newOptions = [...question.Options];
                                    newOptions[index].OptionName = e.target.value;
                                    setQuestionDetails((prevDetails) =>
                                        prevDetails.map((q) =>
                                            q.QuestionID === question.QuestionID
                                                ? { ...q, Options: newOptions }
                                                : q
                                        )
                                    );
                                }}
                            />
                        </div>
                    ))
                ) : (
                    <p className="mb-0">No options available</p>
                )}
            </div>
        );
    };

    const moveItem = (index, direction) => {
        const newQuestionDetails = [...questionDetails];
        if (direction === "up" && index > 0) {
            [newQuestionDetails[index - 1], newQuestionDetails[index]] = [
                newQuestionDetails[index],
                newQuestionDetails[index - 1],
            ];
        } else if (direction === "down" && index < newQuestionDetails.length - 1) {
            [newQuestionDetails[index], newQuestionDetails[index + 1]] = [
                newQuestionDetails[index + 1],
                newQuestionDetails[index],
            ];
        } else {
            console.log("Cannot move item in that direction.");
        }

        setQuestionDetails(newQuestionDetails);
        updateSequence(newQuestionDetails);
    };

    const updateSequence = (details) => {
        const newSequence = details.map((item) => item.QuestionSequence);
        console.log("Updated sequence:", newSequence.join(","));
    };

    const handleCheckboxChange = (questionId) => {
        setQuestionDetails((prevDetails) =>
            prevDetails.map((question) =>
                question.QuestionID === questionId
                    ? { ...question, IsRequired: !question.IsRequired }
                    : question
            )
        );
    };

    const handleDropdownAction = (questionId, action) => {
        if (action === "Activate/Deactivate") {
            setQuestionDetails((prevDetails) =>
                prevDetails.map((question) =>
                    question.QuestionID === questionId
                        ? { ...question, Status: !question.Status }
                        : question
                )
            );
        } else if (action === "Copy") {
            // Handle copy question logic here
            console.log("Copying question:", questionId);
        }
    };

    return (
        <>
            <Header />
            <div className="mt-5">
                {loading ? (
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                ) : (
                    <div className="container">
                        <div className="card col-12 col-lg-8 shadow-sm mx-auto mt-3 p-5">
                            {questionDetails.map((question, index) => (
                                <div className="QuestionContainer mb-3" key={question.QuestionID}>
                                    <div className="p-4">
                                        {renderQuestionInput(question)}
                                        <div className="survey-question">
                                            <div className="d-flex gap-2 align-items-center">
                                                {index + 1}
                                                <input
                                                    value={question.QuestionName}
                                                    onChange={(e) =>
                                                        setQuestionDetails((prevDetails) =>
                                                            prevDetails.map((q) =>
                                                                q.QuestionID === question.QuestionID
                                                                    ? { ...q, QuestionName: e.target.value }
                                                                    : q
                                                            )
                                                        )
                                                    }
                                                    className="Sanket-Control"
                                                />
                                            </div>
                                        </div>
                                        {renderOption(question)}
                                    </div>
                                    <div className="text-end m-0 p-3" style={{ borderTop: "1px solid #ccc" }}>
                                        Required*
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                id="toggleCheckbox"
                                                checked={question.IsRequired}
                                                onChange={() => handleCheckboxChange(question.QuestionID)}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                        <a
                                            className="ActionBtns"
                                            onClick={() => moveItem(index, "up")}
                                        >
                                            <i className="fa-solid fa-arrow-up"></i>
                                        </a>
                                        <a
                                            className="ActionBtns"
                                            onClick={() => moveItem(index, "down")}
                                        >
                                            <i className="fa-solid fa-arrow-down"></i>
                                        </a>
                                        <div className="dropdown d-inline-block align-middle">
                                            <button
                                                className="btn btn-lg"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                &#x22EE;
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                                <li>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => handleDropdownAction(question.QuestionID, "Copy")}
                                                    >
                                                        Copy Question
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => handleDropdownAction(question.QuestionID, "Activate/Deactivate")}
                                                    >
                                                        {question.Status ? "Deactivate Question" : "Activate Question"}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <p className="">
                            <a href="#" className="btn btn-custom">Save Draft</a>
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ManageSurvey;
