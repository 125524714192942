import React, { useState, useEffect } from "react";
import apiService from '../Services/apiService';
import { Link, useNavigate } from "react-router-dom";
import '../styles/LoginCss.css';
import TruveyGreenLogo from '../Asset/Images/TruVey_logo-green.png'
import Header from "./Header";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captchaCode, setCaptchaCode] = useState("");
  const [captchaImage, setCaptchaImage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false); 
   
  const navigate = useNavigate();
  useEffect(() => {
    const token = sessionStorage.getItem("JWTToken");
    if (token) {
      navigate("/SurveyList")
    } else {
      setIsLoggedIn(false);
    }
    
  }, []);
  // Handle form submission
  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setCaptchaError("");

    if (!email || !password ) {
      setErrorMessage("Please fill out all fields.");
      return;
    }

    try {
      const response = await apiService.Userlogin(email, password); // Call login service
      if(response!=null){
        const JWTToken =response.TOKEN;
        if (!response.TOKEN) {
        setErrorMessage("Invalid email or password. Please try again.");
        return;
      }
      else {
        // Redirect to Survey List on successful login
        setErrorMessage("Login Successfully");
        sessionStorage.setItem("UserEmail",email)
        sessionStorage.setItem("JWTToken", JWTToken);
        navigate("/SurveyList");
      }
    }else{
      setErrorMessage("Invalid email or password. Please try again.");
    }
    } catch (error) {
      setErrorMessage(error.message); // Display error message
    }
  };


  return (
    <>
        <Header/>
    <section className="theme-bg mt-5">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center h-100">
          <div className="col-md-7 col-lg-6 col-xl-5">
            <div className="d-none d-md-block text-center" style={{ width: "100%" }}>
              <Link to="/">
                <img src={TruveyGreenLogo} alt="Logo" />
              </Link>
            </div>
          </div>
          <div
            className="col-md-5 col-lg-5 col-xl-5 offset-xl-1 p-3 bg-white"
            style={{
              borderRadius: "1rem",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <div className="p-3">
              <h2 className="text-center fw-bold mb-4">Sign in</h2>
              <form onSubmit={handleLogin}>
                <div className="form-group">
                  <label htmlFor="email" className="form-label">
                    Registered Email <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="form-control mb-2"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password" className="form-label">
                    Password <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="password"
                      id="password"
                      className="form-control rounded-2"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-custom btn-lg btn-block form-control mt-4 mb-4"
                >
                  Login
                </button>
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
              </form>

              <div className="d-flex justify-content-around align-items-center mt-4 mb-4">
                <p>
                  <a href="/forgot-password">Forgot password?</a>
                </p>
                <p>
                  Don't have an account? <a href="/sign-up">Register now</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default Login;
