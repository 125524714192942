import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Component/Login.jsx';
import Home from './Component/Home.jsx';
import SurveyList from './Component/SurveyList.jsx';
import PageNot from './PageNot.jsx';
import ManageSurvey from './Component/ManageSurvey.jsx';
import CreateNew from './Component/CreateNew.jsx';


function App() {
  return (
      <Router>
        <Routes>
          {/* <Route path="/Survey_List" element={<SurveyList />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Login" element={<Login />} />
          <Route path="Login" element={<Login />} />
          <Route path="/SurveyList" element={<SurveyList />} />
          <Route path='/ManageSurvey/:uniqueKey' element={<ManageSurvey/>} />
          <Route path='/Survey/CreateNew' element={<CreateNew/>} />
          {/* Add more routes */}

          <Route path={'*'} element={<PageNot />} />
        </Routes>
    </Router>
  );
}

export default App;
