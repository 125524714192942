import React from "react";
import '../styles/Home.css'
import SurveyImgVideo from '../Asset/Video/Truvey-All-in-One.mp4';
import Header from "./Header";
import HeroVideo from "../Asset/Video/Truvey-Hero-Animation.mp4";
const Home=()=>{
    return(
        <>
        <Header/>
         <div className="bg-white">
      <div className="container pt-5">
        <div className="text-center">
          <h1>Get Powerful Insights. Effortlessly.</h1>
          <p className="lead my-4">
            Create free, customizable surveys with unlimited questions and reports delivered in Excel.
          </p>
          <a href="/SignUp" className="freebtn">Sign up free</a>
        </div>
        
        <div className="video-container mt-5 col-9 mx-auto">
          <video className="w-100" autoPlay loop muted>
            <source src={HeroVideo} type="video/mp4" />
          </video>
        </div>

        <div className="mt-5 mx-auto">
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              <video className="w-100" autoPlay loop muted>
                <source src={SurveyImgVideo} type="video/mp4" />
              </video>
            </div>
            <div className="col-md-6 col-12">
              <div>
                <h3 className="h1">Introducing Truvey Survey Platform!</h3>
              </div>
              <div>
                <p><strong>Tired of complex survey tools and limited features?</strong></p>
                <p>Introducing <strong>Truvey Survey Platform!</strong> Our user-friendly platform empowers you to gather valuable feedback without breaking the bank.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-12 mb-4">
            <div className="card-custom p-4 d-flex">
              <div className="card-body col-md-7 col-12">
                <h2 className="h1">Features</h2>
                <p className="text-justify">
                  Create unlimited surveys with no hidden fees.
                  <br />
                  <b>Customization Unleashed</b>: Design personalized surveys with various question types.
                  <br />
                  <b>Effortless Sharing</b>: Generate unique survey links for easy distribution via email, social media, or email.
                  <br />
                  <b>Data at Your Fingertips</b>: Get comprehensive reports in Excel format for further analysis and customization.
                  <br />
                  No Limitations: No restrictions on the number of questions or responses.
                </p>
              </div>
              <div className="text-center col-12 col-md-3">
                <img className="w-100" src="https://cdn.dribbble.com/users/1386214/screenshots/5546601/newfeatures_2.gif" alt="Alternate Text" />
              </div>
            </div>
          </div>

          <div className="col-md-12 mb-4">
            <div className="card-custom p-4 row">
              <div className="col-md-3 col-12 text-center">
                <img className="w-100" src="https://i.pinimg.com/originals/e1/a3/65/e1a36584839490f21c603cec08b94337.gif" alt="Alternate Text" />
              </div>
              <div className="col-md-6 col-12">
                <h3 className="h1">Benefits</h3>
                <p>
                  Gain valuable customer insights to improve products and services.
                  <br />
                  <b>Employee Feedback</b>: Understand employee needs and satisfaction for a better work environment.
                  <br />
                  <b>Event Planning</b>: Gauge interest and gather participant preferences for successful events.
                  <br />
                  <b>Academic Research</b>: Collect data efficiently and analyze it easily for impactful research projects.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5 bg-theme p-5">
        <h2 className="text-center mb-4">Frequently Asked Questions (FAQs)</h2>
        <div className="container">
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <strong>➤ What is Truvey Survey Platform?</strong>
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>Truvey Survey Platform</strong> Truvey Survey Platform is a user-friendly survey creation tool that allows you to create free, customizable surveys with unlimited questions and generate reports delivered in Excel format.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <strong>➤ How can I create surveys with Truvey Survey Platform?</strong>
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            You can easily create surveys by signing up on our website, designing personalized surveys with various question types, and sharing unique survey links for easy distribution. With customized thumbnail.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <strong>➤ What is Multiple Choice ?</strong>
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>Multiple-choice questions </strong>are fundamental survey questions that provide respondents to select one option from multiple options available (Ideal for collecting preferences or specific information).
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <strong>➤ What are Check Boxes ?</strong>
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>Check boxes</strong> also known as multi-select questions offers respondents to pick more than one answer alternatives (Useful for gathering broader opinions or preferences).
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            <strong>➤ What is Short Answer ?</strong>
                        </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>Text box</strong> Prompt respondents to provide concise answers to your question in a single line (Good for gathering quick feedback or specific details).
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            <strong>➤ What is Paragraph ?</strong>
                        </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>Paragraph</strong>  allows respondents for more detailed and in-depth written responses/answers. (Suitable for gathering explanations or open-ended feedback)
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            <strong>➤ What is Rating Bar ?</strong>
                        </button>
                    </h2>
                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>Rating Bar</strong> present a scale for respondents to choose a rating or score based on their opinion (Great for gauging satisfaction levels or preferences on a spectrum). 
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                            <strong>➤ What is answertype Mobile number ?</strong>
                        </button>
                    </h2>
                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>Mobile Number</strong> allow respondents to input their mobile phone number directly (Useful for further communication or verification).
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                            <strong>➤ What is Answer in number ?</strong>
                        </button>
                    </h2>
                    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>Answer in number</strong> ask for a numerical response from participants. (Ideal for collecting quantitative data or values).
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTen">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                            <strong>➤ What is answertype Email ?</strong>
                        </button>
                    </h2>
                    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            Collect <strong>email</strong> addresses from participants for further communication or follow-up. (Handy for building an email list or sending additional information).
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEleven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                            <strong>➤ What is answertype Date ?</strong>
                        </button>
                    </h2>
                    <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>Date</strong> Provide respondents with a calendar tool to select a specific date (Useful for collecting information about events or deadlines).
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

      <div className="container-fluid mt-5 p-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-12 card-custom p-3">
              <h2 className="text-center mb-4 mt-5">Contact Us</h2>
              <div className="col-lg-12 col-md-6 mt-3 align-items-center justify-content-center m-auto">
                <div className="card-body bg-gray">
                  <div className="row">
                    <div className="col-lg-12 mb-2">
                      <div className="form-group">
                        <div className="input-group">
                          <input className="form-control" type="text" required placeholder="Your Name" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-2">
                      <div className="form-group">
                        <div className="input-group">
                          <input className="form-control" type="email" required placeholder="Your Email Address" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-2">
                      <div className="form-group">
                        <div className="input-group">
                          <input className="form-control" type="text" required placeholder="Your Phone Number" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-2">
                      <div className="form-group">
                        <div className="input-group">
                          <textarea className="form-control" rows="4" required placeholder="Your Message..."></textarea>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-12 mb-2">
                      <div className="form-group">
                        <label htmlFor="txtVerificationCode"><b>Verification Code</b><span className="star">*</span></label>
                        <img className="form-control" height="70px" width="250px" alt="Verification Code" />
                        <input className="form-control" type="text" width="250px" required placeholder="Enter Verification Code" />
                      </div>
                    </div> */}
                    <div className="col-lg-6 text-center m-auto mt-4">
                      <button className="btn-custom form-control rounded-pill">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <img src="https://assets-v2.lottiefiles.com/a/3f3c800e-1168-11ee-8c3b-131af4d303a5/RVW0iShHMK.gif" className="w-100" alt="Alternate Text" />
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mt-5 p-5" style={{ background: '#3fb390' }}>
        <div className="p-4 rounded text-white">
          <h4><strong>Ready to Get Started?</strong></h4>
          <p className="my-3">Visit our website today and experience the power of free, customizable surveys!</p>
          <a href="../CreateSurvey" className="freebtn">Get Started</a>
        </div>
      </div>
    </div>
        </>
    )
}
export default Home;