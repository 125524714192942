import axios from 'axios';

// Base configuration for axios
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://api.survey4us.com/api', // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the JWT token in the headers
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token'); // Retrieve token from local storage
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Service methods
const apiService = {
  // User login
  Userlogin: async (email, password) => {
    try {
      const response = await apiClient.post('/Login/ValidateUser', { email, password });
      return response.data; // Return the response data if the request was successful
    } catch (error) {
      if (error.response) {
        // If error.response exists, that means we got a response from the server
        // Handle specific error status codes, like 404 (Not Found) or 401 (Unauthorized)
        console.error('Response error:', error.response);
        return error.response; // Return only the response object
      } else {
        // This case handles other kinds of errors (like network issues, no response, etc.)
        console.error('Error without response:', error);
        throw error; // Rethrow the error or handle it as needed
      }
    }
  },
  
  EncryptJWT: async (token)=>{
    try {
      const response = await apiClient.post('/Login/GetRoles', { token });
      return response.data; 
    } catch (error) {
      if (error.response) {
        console.error('Response error:', error.response);
        return error.response;
      } else {
        console.error('Error without response:', error);
        throw error;  
      }
    }
  },
  // Fetch survey Details
  GetSurveyDetails: async (Token) => {
    const response = await apiClient.post('/Survey/SurveyDetails',{Token});
    return response.data;
  },
   // Fetch user profile
  GetQuestionDetails: async (Token,SurveyUID) => {
    const response = await apiClient.post('/Survey/SurveyQuestions',{Token,SurveyUID});
    return response.data;
  },

  // Update user profile
  updateUserProfile: async (profileData) => {
    const response = await apiClient.put('/users/profile', profileData);
    return response.data;
  },

 

  // Create a new survey
  createSurvey: async (surveyData) => {
    const response = await apiClient.post('/surveys', surveyData);
    return response.data;
  },

  // Refresh CAPTCHA (example)
  refreshCaptcha: async () => {
    const response = await apiClient.get('/auth/refresh-captcha');
    return response.data;
  },
};

export default apiService;
